import { template as compiler } from "discourse-common/lib/raw-handlebars";
import { addRawTemplate } from "discourse-common/lib/raw-templates";

let template = compiler({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "         "
    + container.escapeExpression((lookupProperty(helpers,"d-icon")||(depth0 && lookupProperty(depth0,"d-icon"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"heart",{"name":"d-icon","hash":{},"hashTypes":{},"hashContexts":{},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":6,"column":9},"end":{"line":6,"column":27}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + container.escapeExpression((lookupProperty(helpers,"d-icon")||(depth0 && lookupProperty(depth0,"d-icon"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"far-heart",{"name":"d-icon","hash":{},"hashTypes":{},"hashContexts":{},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":8,"column":10},"end":{"line":8,"column":32}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"bottom-topicinfo\">\n  <div class=\"topic-num\">\n    <a href=\"javascript:void(0)\" class=\" num-likes click-likes "
    + alias2(lookupProperty(helpers,"get").call(alias1,"topic.likeclass",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":3,"column":63},"end":{"line":3,"column":82}}}))
    + "\"\n      data-topic-id=\""
    + alias2(lookupProperty(helpers,"get").call(alias1,"topic.relative_post_id",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":4,"column":21},"end":{"line":4,"column":47}}}))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,"topic.liked",{"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":9,"column":13}}})) != null ? stack1 : "")
    + "\n      <span class=\"likenum\">\n        "
    + alias2(lookupProperty(helpers,"get").call(alias1,"view.topic.op_like_count",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":36}}}))
    + "\n      </span>\n\n    </a>\n    <a href="
    + alias2(lookupProperty(helpers,"get").call(alias1,"topic.summaryUrl",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":16,"column":32}}}))
    + " class=\"num-likes\">\n      <img src=\"/images/posted_post.png\" alt=\"\"  class=\"topic-list-icon\">\n      "
    + alias2(lookupProperty(helpers,"get").call(alias1,"view.topic.reply_count",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":18,"column":6},"end":{"line":18,"column":32}}}))
    + "\n    </a>\n\n        <a href=\"javascript:void(0)\" class=\"num-likes click-share\" data-topic-slug=\""
    + alias2(lookupProperty(helpers,"get").call(alias1,"topic.slug",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":21,"column":84},"end":{"line":21,"column":98}}}))
    + "\"\n      data-topic-id=\""
    + alias2(lookupProperty(helpers,"get").call(alias1,"topic.id",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":22,"column":21},"end":{"line":22,"column":33}}}))
    + "\">\n     <img src=\"/images/share_post.png\" alt=\"\"  class=\"topic-list-icon\">\n      <span>\n        share\n      </span>\n    </a>\n  </div>\n</div>\n";
},"useData":true});

addRawTemplate("list-function", template, { core: true });
export default template;