define("discourse/plugins/moyun/discourse/routes/sd25th", ["exports", "discourse/routes/discourse", "discourse/lib/ajax", "discourse/models/composer", "@ember/service"], function (_exports, _discourse, _ajax, _composer, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Route for the path `/notebook` as defined in `../notebook-route-map.js.es6`.
   */
  var _default = _exports.default = _discourse.default.extend({
    composer: (0, _service.service)(),
    beforeModel() {
      let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      if (!isMobile) {
        this.router.transitionTo("/latest");
      }
    },
    async model() {
      // const timestamp = new Date().getTime();

      // setTimeout(() => {
      //   this.composer.open({
      //     action: Composer.CREATE_TOPIC,
      //     draftKey: Composer.NEW_TOPIC_KEY,
      //     replyflg: true,
      //     title:"Shanghai Airport Link Line"+timestamp,
      //     categoryId:5, //留言分类id
      //   });
      // }, 500)
    }
  });
});