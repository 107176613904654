define("discourse/plugins/moyun/discourse/controllers/pointsmanagement", ["exports", "@ember/controller", "@ember/service", "@ember/object", "discourse/lib/ajax", "@glimmer/tracking", "discourse-common/utils/decorators"], function (_exports, _controller, _service, _object, _ajax, _tracking, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PoinstManagementController = _exports.default = (_dec = (0, _decorators.default)(), (_class = class PoinstManagementController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "composer", _descriptor, this);
      // 时间赛选
      _initializerDefineProperty(this, "searchloadin", _descriptor2, this);
      _initializerDefineProperty(this, "pluginsId", _descriptor3, this);
      _initializerDefineProperty(this, "period", _descriptor4, this);
      _initializerDefineProperty(this, "total", _descriptor5, this);
      _initializerDefineProperty(this, "currentPage", _descriptor6, this);
      _initializerDefineProperty(this, "articles", _descriptor7, this);
      _initializerDefineProperty(this, "list", _descriptor8, this);
      _initializerDefineProperty(this, "articletypes", _descriptor9, this);
      _initializerDefineProperty(this, "columns", _descriptor10, this);
    }
    init() {
      super.init(...arguments);
      this._search();
    }
    _search() {
      // /moyun/leadboard/${this.pluginsId}?page=${this.currentPage}&limit=${this.articles}&period=${this.period}
      (0, _ajax.ajax)(`/moyun/leadboard/${this.pluginsId}?page=${this.currentPage}&period=${this.period}`, {
        type: "GET",
        headers: {
          Accept: "application/json"
        }
      }).then(async results => {
        this.list = results.users;
        this.total = results.users.length;
        console.log(this.total, this.list);
      });
    }
    pluginsActions() {
      let plugins = this.model.gamification_leaderboards;
      return plugins.map(item => {
        return {
          id: item.id,
          name: item.name
        };
      });
    }
    // 分页
    pagChange(currentPage, articles) {
      console.log(currentPage, articles);
      this.articles = articles;
      this.currentPage = currentPage;
      this._search();
    }
    // 赛选
    changePeriod(Period) {
      this.period = Period;
      this.currentPage = 0;
      this._search();
    }
    pluginsChange(id) {
      this.currentPage = 0;
      this.pluginsId = id;
      this._search();
    }
    search() {
      this.searchloadin = true;
      setTimeout(() => {
        this.searchloadin = false;
      }, 3000);
      console.log(this.period, this.pluginsId);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "composer", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "searchloadin", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pluginsId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 2;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "period", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "yearly";
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "total", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "currentPage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "articles", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1000;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "list", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "articletypes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [{
        name: "10 / page",
        id: 10
      }, {
        name: "20 / page",
        id: 20
      }, {
        name: "30 / page",
        id: 30
      }, {
        name: "40 / page",
        id: 40
      }];
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "columns", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [{
        title: "chat.create_channel.choose_category.order"
      }, {
        title: "chat.about_view.name"
      }, {
        title: "admin.dashboard.mobile_title"
      }, {
        title: "user.title.title"
      }, {
        title: "gamification.score"
      }];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "pluginsActions", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "pluginsActions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pagChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "pagChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changePeriod", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changePeriod"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pluginsChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "pluginsChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "search", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype)), _class));
});