import { template } from "@ember/template-compiler";
import Component from "@ember/component";
import { inject as service } from "@ember/service";
import { concat, fn, hash } from "@ember/helper";
import { on, next } from "@ember/modifier";
import { eq } from "truth-helpers";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import ComboBox from "select-kit/components/combo-box";
import icon from "discourse-common/helpers/d-icon";
export default class Pagination extends Component {
    @tracked
    total = this.args.total || 0;
    @tracked
    currentPage = this.args.currentPage || 1;
    @tracked
    articles = this.args.articles || 10;
    @tracked
    articletypes = [
        {
            name: "10 / page",
            id: 10
        },
        {
            name: "20 / page",
            id: 20
        },
        {
            name: "30 / page",
            id: 30
        },
        {
            name: "40 / page",
            id: 40
        }
    ];
    get totalPages() {
        return Math.ceil(this.total / this.articles);
    }
    get pages() {
        const pageNumbers1 = [];
        const maxVisiblePages1 = 5; // 控制显示的页码数量
        const halfVisible1 = Math.floor(maxVisiblePages1 / 2);
        let startPage1 = Math.max(1, this.currentPage - halfVisible1);
        let endPage1 = Math.min(this.totalPages, this.currentPage + halfVisible1);
        if (this.currentPage <= halfVisible1) {
            endPage1 = Math.min(this.totalPages, maxVisiblePages1);
        }
        if (this.currentPage + halfVisible1 >= this.totalPages) {
            startPage1 = Math.max(1, this.totalPages - maxVisiblePages1 + 1);
        }
        for(let i1 = startPage1; i1 <= endPage1; i1++){
            pageNumbers1.push(i1);
        }
        return pageNumbers1;
    }
    get showFirstEllipsis() {
        return this.currentPage > 3 && this.totalPages !== this.pages.length;
    }
    get showLastEllipsis() {
        return (this.currentPage < this.totalPages - 2 && this.totalPages !== this.pages.length);
    }
    get hasPrevious() {
        return this.currentPage > 1;
    }
    get hasNext() {
        return this.currentPage < this.totalPages;
    }
    @action
    goToPage(page1) {
        this.currentPage = page1;
        this.onPageChange?.(this.currentPage, this.articles);
    }
    @action
    previousPage() {
        if (this.hasPrevious) {
            this.goToPage(this.currentPage - 1);
        }
    }
    @action
    nextPage() {
        if (this.hasNext) {
            this.goToPage(this.currentPage + 1);
        }
    }
    @action
    handleArticlesChange(newArticles1) {
        this.articles = newArticles1;
        this.goToPage(1);
    }
    static{
        template(`
    <div class="review-Pagination">
      <div class="Pagination">
        <span class="total">in total {{this.total}} Item</span>
        <div class="pagefun">

          <div {{on "click" (fn this.previousPage)}}>
            {{icon "chevron-left"}}
          </div>

          {{#if this.showFirstEllipsis}}
            <div {{on "click" (fn this.goToPage 1)}} class="page-number">1</div>
            <span class="ellipsis">...</span>
          {{/if}}

          {{#each this.pages as |page|}}
            <div
              {{on "click" (fn this.goToPage page)}}
              class={{if (eq page this.currentPage) "pages" ""}}
            >
              {{page}}
            </div>
          {{/each}}

          {{#if this.showLastEllipsis}}
            <span class="ellipsis">...</span>
            <div
              {{on "click" (fn this.goToPage this.totalPages)}}
              class="page-number"
            >
              {{this.totalPages}}
            </div>
          {{/if}}

          <div {{on "click" this.nextPage}} class="page-button">
            {{icon "chevron-right"}}
          </div>
        </div>

        <div class="page">
          <ComboBox
            @value={{this.articles}}
            @content={{this.articletypes}}
            @onChange={{this.handleArticlesChange}}
            class="select-xn"
            @options={{hash castInteger=true}}
          />
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
