define("discourse/plugins/moyun/discourse/controllers/sd25th", ["exports", "@ember/controller", "discourse/lib/ajax", "@glimmer/tracking", "@ember/service", "@ember/object", "@ember/template", "discourse/models/composer", "discourse/lib/ajax-error"], function (_exports, _controller, _ajax, _tracking, _service, _object, _template, _composer, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let BannerManagerController = _exports.default = (_class = class BannerManagerController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
      _initializerDefineProperty(this, "dialog", _descriptor2, this);
      _initializerDefineProperty(this, "composer", _descriptor3, this);
      _initializerDefineProperty(this, "site", _descriptor4, this);
      _initializerDefineProperty(this, "router", _descriptor5, this);
      _initializerDefineProperty(this, "formCover", _descriptor6, this);
      _initializerDefineProperty(this, "formContent", _descriptor7, this);
      _initializerDefineProperty(this, "isLoading", _descriptor8, this);
      _initializerDefineProperty(this, "captcha", _descriptor9, this);
      // 绑定验证码实例函数。该函数为固定写法，无需修改
      _defineProperty(this, "getInstance", instance => {
        console.log(instance);
        this.captcha = instance;
      });
      _defineProperty(this, "onBizResultCallback", () => {
        this.submitForm();
      });
    }
    init() {
      super.init(...arguments);
      this.formContent = '';
      this.uploadSuccess = this.uploadSuccess.bind(this);
      // initAliyunCaptcha({
      //   SceneId: 'l2zmj95w', // 场景ID。
      //   prefix: '1awd1c', // 身份标。
      //   mode: 'popup', // 验证码模式。
      //   element: '#captcha-element', //页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
      //   button: '#submitBtn', // 触发验证码弹窗的元素。
      //   captchaVerifyCallback: this.captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，
      //   onBizResultCallback: this.onBizResultCallback, // 业务请求结果回调函数，
      //   getInstance: this.getInstance, // 绑定验证码实例函数，
      //   slideStyle: {
      //     width: 360,
      //     height: 40,
      //   }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
      //   language: 'en',  // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
      //   region: 'cn', //验证码示例所属地区，支持中国内地（cn）、新加坡（sgp）
      // });
    }

    async captchaVerifyCallback(captchaVerifyParam) {
      console.log(captchaVerifyParam, 'ppppppp');
      // const res = await ajax(`https://captcha.cn-shanghai.aliyuncs.com/VerifyIntelligentCaptcha`, {
      //   type: "POST",
      //   data:captchaVerifyParam,
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded', // 根据API要求调整
      //   },
      // })
      // console.log(res)

      // // 2.构造标准返回参数
      const verifyResult = {
        captchaResult: true,
        // 验证码验证是否通过，boolean类型，必选
        bizResult: true // 业务验证是否通过，boolean类型，可选；若为无业务验证结果的场景，bizResult可以为空
      };
      return verifyResult;
    }
    uploadSuccess(upload) {
      console.log('Upload object:', upload);
      if (upload && upload.url) {
        const url = upload.short_url;
        const markdownImage = `![image](${url})`;
        this.formContent = `${this.formContent}\n${markdownImage}`;
        console.log('Updated formContent:', this.formContent);
      } else {
        console.log('Invalid upload object');
      }
    }

    // 提交表单
    async submitForm() {
      this.isLoading = true;
      this.formContent = (0, _template.htmlSafe)(this.formContent);
      // console.log('111',this.formContent);
      // console.log('111',this.composer.model.reply);
      try {
        // this.loading = true;
        const res = await (0, _ajax.ajax)(`/moyun/leave_msg`, {
          type: "POST",
          data: {
            raw: this.formContent.toString()
            // raw:this.composer.model.reply
          }
        });
        this.dialog.alert({
          message: 'submit successfully!',
          class: 'moyunMsg',
          didConfirm: () => {
            this.router.transitionTo('/latest');
          }
        });
      } catch (error) {
        this.dialog.alert({
          message: (0, _ajaxError.extractError)(error),
          type: 'error',
          class: 'moyunError'
        });
      } finally {
        this.isLoading = false;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "composer", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "formCover", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "formContent", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "captcha", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "submitForm", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submitForm"), _class.prototype)), _class);
});