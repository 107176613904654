import { template as compiler } from "discourse-common/lib/raw-handlebars";
import { addRawTemplate } from "discourse-common/lib/raw-templates";

let template = compiler({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <td class=\"bulk-select topic-list-data\">\n    <label for=\"bulk-select-"
    + alias2(lookupProperty(helpers,"get").call(alias1,"topic.id",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":5,"column":28},"end":{"line":5,"column":40}}}))
    + "\">\n      <input\n        type=\"checkbox\"\n        class=\"bulk-select\"\n        id=\"bulk-select-"
    + alias2(lookupProperty(helpers,"get").call(alias1,"topic.id",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":9,"column":36}}}))
    + "\"\n      />\n    </label>\n  </td>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"cate\">\n              audit failure\n            </span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"topic-link")||(depth0 && lookupProperty(depth0,"topic-link"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"topic",{"name":"topic-link","hash":{"class":"raw-link raw-topic-link"},"hashTypes":{"class":"StringLiteral"},"hashContexts":{"class":depth0},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":59,"column":4},"end":{"line":59,"column":57}}}))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          &nbsp;"
    + container.escapeExpression((lookupProperty(helpers,"topic-featured-link")||(depth0 && lookupProperty(depth0,"topic-featured-link"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"topic",{"name":"topic-featured-link","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":63,"column":16},"end":{"line":63,"column":46}}}));
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"list-imge\">\n      <img src="
    + container.escapeExpression(lookupProperty(helpers,"get").call(depth0 != null ? depth0 : (container.nullContext || {}),"topic.image_url",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":85,"column":15},"end":{"line":85,"column":34}}}))
    + " alt=\"\">\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias3((lookupProperty(helpers,"raw-plugin-outlet")||(depth0 && lookupProperty(depth0,"raw-plugin-outlet"))||alias2).call(alias1,{"name":"raw-plugin-outlet","hash":{"name":"topic-list-before-columns"},"hashTypes":{"name":"StringLiteral"},"hashContexts":{"name":depth0},"types":[],"contexts":[],"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":55}}}))
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,"bulkSelectEnabled",{"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(1, data, 0),"inverse":container.noop,"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":13,"column":7}}})) != null ? stack1 : "")
    + "\n<td class=\"main-link clearfix topic-list-data\" colspan=\"1\">"
    + alias3((lookupProperty(helpers,"raw-plugin-outlet")||(depth0 && lookupProperty(depth0,"raw-plugin-outlet"))||alias2).call(alias1,{"name":"raw-plugin-outlet","hash":{"name":"topic-list-before-link"},"hashTypes":{"name":"StringLiteral"},"hashContexts":{"name":depth0},"types":[],"contexts":[],"data":data,"loc":{"start":{"line":23,"column":2},"end":{"line":23,"column":54}}}))
    + "\n  <div class=\"topiclist-main\">\n    <div class=\"user-title\">\n      <a\n        aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"latest_poster_link",{"name":"i18n","hash":{"username":"topic.creator.username"},"hashTypes":{"username":"PathExpression"},"hashContexts":{"username":depth0},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":30,"column":10}}}))
    + "\"\n        data-user-card=\""
    + alias3(lookupProperty(helpers,"get").call(alias1,"topic.creator.username",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":31,"column":50}}}))
    + "\"\n      >\n        "
    + alias3((lookupProperty(helpers,"avatar")||(depth0 && lookupProperty(depth0,"avatar"))||alias2).call(alias1,"topic.creator",{"name":"avatar","hash":{"imageSize":"small"},"hashTypes":{"imageSize":"StringLiteral"},"hashContexts":{"imageSize":depth0},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":33,"column":50}}}))
    + "\n      </a>\n      <div class=\"topic-userinfo\">\n        <span class=\"topic-name\">\n          "
    + alias3(lookupProperty(helpers,"get").call(alias1,"topic.creator.name",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":38,"column":10},"end":{"line":38,"column":32}}}))
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,"topic.isusertopic",{"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(3, data, 0),"inverse":container.noop,"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":40,"column":10},"end":{"line":44,"column":17}}})) != null ? stack1 : "")
    + "        </span>\n          ·\n        <span class=\"bumpedat\">\n          "
    + alias3((lookupProperty(helpers,"format-date")||(depth0 && lookupProperty(depth0,"format-date"))||alias2).call(alias1,"topic.created_at",{"name":"format-date","hash":{"leaveAgo":"true"},"hashTypes":{"leaveAgo":"StringLiteral"},"hashContexts":{"leaveAgo":depth0},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":49,"column":10},"end":{"line":49,"column":58}}}))
    + "\n        </span>\n      </div>\n    </div>\n\n    <div>\n      <span class=\"link-top-line\">"
    + alias3((lookupProperty(helpers,"raw-plugin-outlet")||(depth0 && lookupProperty(depth0,"raw-plugin-outlet"))||alias2).call(alias1,{"name":"raw-plugin-outlet","hash":{"name":"topic-list-before-status"},"hashTypes":{"name":"StringLiteral"},"hashContexts":{"name":depth0},"types":[],"contexts":[],"data":data,"loc":{"start":{"line":56,"column":8},"end":{"line":56,"column":62}}}))
    + alias3((lookupProperty(helpers,"raw")||(depth0 && lookupProperty(depth0,"raw"))||alias2).call(alias1,"topic-status",{"name":"raw","hash":{"topic":"topic"},"hashTypes":{"topic":"PathExpression"},"hashContexts":{"topic":depth0},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":57,"column":8},"end":{"line":57,"column":43}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,"topic.showtitel",{"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(5, data, 0),"inverse":container.noop,"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":58,"column":2},"end":{"line":60,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,"topic.featured_link",{"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(7, data, 0),"inverse":container.noop,"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":62,"column":8},"end":{"line":64,"column":16}}})) != null ? stack1 : "")
    + alias3((lookupProperty(helpers,"raw-plugin-outlet")||(depth0 && lookupProperty(depth0,"raw-plugin-outlet"))||alias2).call(alias1,{"name":"raw-plugin-outlet","hash":{"name":"topic-list-after-title"},"hashTypes":{"name":"StringLiteral"},"hashContexts":{"name":depth0},"types":[],"contexts":[],"data":data,"loc":{"start":{"line":65,"column":8},"end":{"line":65,"column":60}}}))
    + alias3((lookupProperty(helpers,"raw")||(depth0 && lookupProperty(depth0,"raw"))||alias2).call(alias1,"list.unread-indicator",{"name":"raw","hash":{"unreadClass":"unreadClass","topicId":"topic.id","includeUnreadIndicator":"includeUnreadIndicator"},"hashTypes":{"unreadClass":"PathExpression","topicId":"PathExpression","includeUnreadIndicator":"PathExpression"},"hashContexts":{"unreadClass":depth0,"topicId":depth0,"includeUnreadIndicator":depth0},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":66,"column":8},"end":{"line":71,"column":11}}}))
    + "</span>\n      "
    + alias3((lookupProperty(helpers,"raw")||(depth0 && lookupProperty(depth0,"raw"))||alias2).call(alias1,"list.topic-excerpt",{"name":"raw","hash":{"topic":"topic"},"hashTypes":{"topic":"PathExpression"},"hashContexts":{"topic":depth0},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":75,"column":6},"end":{"line":75,"column":46}}}))
    + "\n"
    + alias3((lookupProperty(helpers,"raw")||(depth0 && lookupProperty(depth0,"raw"))||alias2).call(alias1,"list-function",{"name":"raw","hash":{"topic":"topic"},"hashTypes":{"topic":"PathExpression"},"hashContexts":{"topic":depth0},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":77,"column":6},"end":{"line":77,"column":42}}}))
    + alias3((lookupProperty(helpers,"raw-plugin-outlet")||(depth0 && lookupProperty(depth0,"raw-plugin-outlet"))||alias2).call(alias1,{"name":"raw-plugin-outlet","hash":{"name":"topic-list-main-link-bottom"},"hashTypes":{"name":"StringLiteral"},"hashContexts":{"name":depth0},"types":[],"contexts":[],"data":data,"loc":{"start":{"line":79,"column":6},"end":{"line":79,"column":63}}}))
    + "\n    </div>\n  </div>\n   <a href="
    + alias3(lookupProperty(helpers,"get").call(alias1,"topic.summaryUrl",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":82,"column":11},"end":{"line":82,"column":31}}}))
    + " >\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,"topic.image_url",{"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(9, data, 0),"inverse":container.noop,"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":83,"column":2},"end":{"line":87,"column":9}}})) != null ? stack1 : "")
    + "  </a>\n</td>\n";
},"useData":true});

addRawTemplate("list/topic-list-item", template, { core: true });
export default template;