import { template } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
import i18n from "discourse-common/helpers/i18n";
const PoweredByDiscourse = template(`
  {{!-- template-lint-disable link-rel-noopener
  <a
    class="powered-by-discourse"
    href="https://discourse.org/powered-by"
    target="_blank"
  >
    <span class="powered-by-discourse__content">
      <span class="powered-by-discourse__logo">
        {{icon "fab-discourse"}}
      </span>
      <span>{{i18n "powered_by_discourse"}}</span>
    </span>
  </a> --}}
  <div class="Footer-conmunity">
    <div class="conmunity">
      <a href="/user-terms" style="color:#565656">Term & Conditions</a>
      <a href="/privacy-policy" style="color:#565656">Privacy Policy</a>
      <div>Powered by Shanghai Daily</div>
    </div>
    <div class="icpmsg"><a
        href="https://beian.miit.gov.cn/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >沪ICP备05050403号-10</a>
      <img class='icon-beian' src="/images/icon-beian.png" alt=""/>
      <a
        target="_blank"
        href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602007041"
        rel="noopener noreferrer nofollow"
      >沪公网安备 31010602007041号</a>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PoweredByDiscourse;
