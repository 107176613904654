define("discourse/plugins/moyun/discourse/components/uppy-msg-uploader", ["exports", "@ember/component", "@ember/object/computed", "discourse/mixins/uppy-upload", "discourse-common/utils/decorators", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _computed, _uppyUpload, _decorators, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label
    class="btn moyun-msgbtn"
    disabled={{this.uploading}}
  >
    <div style="display: flex;align-items: center;">
      <img class="upImg" src="/images/newBtn.jpg" alt="">
      <div class="msg-text">Please click to upload photos</div>
    </div>
    <input
      class="hidden-upload-field"
      disabled={{this.uploading}}
      type="file"
      accept="image/*"
    />
  </label>
  */
  {
    "id": "2a+bGDK8",
    "block": "[[[10,\"label\"],[14,0,\"btn moyun-msgbtn\"],[15,\"disabled\",[30,0,[\"uploading\"]]],[12],[1,\"\\n  \"],[10,0],[14,5,\"display: flex;align-items: center;\"],[12],[1,\"\\n    \"],[10,\"img\"],[14,0,\"upImg\"],[14,\"src\",\"/images/newBtn.jpg\"],[14,\"alt\",\"\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"msg-text\"],[12],[1,\"Please click to upload photos\"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"input\"],[14,0,\"hidden-upload-field\"],[15,\"disabled\",[30,0,[\"uploading\"]]],[14,\"accept\",\"image/*\"],[14,4,\"file\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/moyun/discourse/components/uppy-msg-uploader.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(_uppyUpload.default, {
    id: "uppy-msg-uploader",
    tagName: "span",
    type: "message-board",
    uploadRootPath: "/uploads",
    uploadUrl: "/moyun/upload_25th",
    // // direct s3 backups
    // @discourseComputed("localBackupStorage")
    // useMultipartUploadsIfAvailable(localBackupStorage) {
    //   return !localBackupStorage && this.siteSettings.enable_direct_s3_uploads;
    // },

    // // local backups
    // useChunkedUploads: alias("localBackupStorage"),

    // @discourseComputed("uploading", "uploadProgress")
    // uploadButtonText(uploading, progress) {
    //   return uploading
    //     ? I18n.t("admin.backups.upload.uploading_progress", { progress })
    //     : I18n.t("admin.backups.upload.label");
    // },

    validateUploadedFilesOptions() {
      return {
        skipValidation: true
      };
    },
    validateUploadedFilesOptions() {
      return {
        imagesOnly: true
      };
    },
    uploadDone(upload) {
      this.done(upload);
    }
  }));
});