define("discourse/plugins/moyun/discourse/templates/sd25th", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="message-board-wrapper">
    <img class="msgb_top" src="/images/new25bg.png" alt="">
    <div class="header">
      <h1 class="title">Headlines & Skylines</h1>
      <div class="greeting">
        <div>We hope you enjoyed this installment of our 25th Anniversary Exhibition.</div>
        <div>Please share your thoughts for Shanghai, or Shanghai Daily.</div>
        <div>We’d love to hear from you!</div>
      </div>
    </div>
  
    <div class="msgForm">
      <div class="content">
        <Textarea @value={{this.formContent}} placeholder="Your thoughts matter to us! Feel free to leave a comment" />
      </div>
      {{!-- <div class="uploadImg">
        <UppyImageUploader @msgBoard={{true}} @imageUrl={{this.formCover}} @type="message_board_image"
          @id="message-uploader" />
      </div> --}}
  
  
      {{!-- <a id="mobile-file-upload" class="btn btn-default no-text mobile-file-upload
                   " aria-label={{i18n "composer.upload_title" }}>
        {{d-icon "upload"}}
      </a> --}}
      {{!-- <input type="file" id={{this.fileInputId}} class={{this.fileInputClass}} accept={{this.acceptedFormats}}
        multiple={{this.allowMultiple}} disabled={{this.fileInputDisabled}} /> --}}
  
      <div class="uploadImg">
        <UppyMsgUploader @done={{this.uploadSuccess}}></UppyMsgUploader>
      </div>
      {{!--
      <ImagesUploader @uploading={{this.uploading}} @done={{this.uploadDone}} class="pull-right" /> --}}
  
      {{!-- <div class="submitBtn" onclick={{action "submitForm" }}>
        <div style="display: flex; align-items: center; justify-content: center">
        <span style="margin-right: 10px;">Send</span>
        {{#if this.isLoading}}
        {{loading-spinner size="small"}}
        {{/if}}
        </div>
      </div> --}}
        {{!-- <div class="submitBtn" id="submitBtn"> --}}
        <div class="submitBtn" onclick={{action "submitForm" }}>
        <div style="display: flex; align-items: center; justify-content: center">
        <span style="margin-right: 10px;">Send</span>
        {{#if this.isLoading}}
        {{loading-spinner size="small"}}
        {{/if}}
        </div>
      </div>
  
    </div>
    {{!-- <div class="msgFormNew">
      <ComposerEditor @magBoardTxt={{true}} @topic={{this.composer.topic}} @composer={{this.composer.model}}
        @lastValidatedAt={{this.composer.lastValidatedAt}} @canWhisper={{this.composer.canWhisper}}
        @storeToolbarState={{this.composer.storeToolbarState}} @onPopupMenuAction={{this.composer.onPopupMenuAction}}
        @showUploadModal={{route-action "showUploadSelector" }} @popupMenuOptions={{this.composer.popupMenuOptions}}
        @draftStatus={{this.composer.model.draftStatus}} @isUploading={{this.composer.isUploading}}
        @isProcessingUpload={{this.composer.isProcessingUpload}} @allowUpload={{this.composer.allowUpload}}
        @uploadIcon={{this.composer.uploadIcon}} @isCancellable={{this.composer.isCancellable}}
        @uploadProgress={{this.composer.uploadProgress}} @groupsMentioned={{this.composer.groupsMentioned}}
        @cannotSeeMention={{this.composer.cannotSeeMention}} @hereMention={{this.composer.hereMention}}
        @importQuote={{this.composer.importQuote}} @togglePreview={{this.composer.togglePreview}}
        @processPreview={{this.composer.showPreview}} @showToolbar={{this.composer.showToolbar}}
        @afterRefresh={{this.composer.afterRefresh}} @focusTarget={{this.composer.focusTarget}}
        @disableTextarea={{this.composer.disableTextarea}} @formTemplateIds={{this.composer.formTemplateIds}}
        @formTemplateInitialValues={{this.composer.formTemplateInitialValues}}
        @onSelectFormTemplate={{this.composer.onSelectFormTemplate}}>
        {{yield}}
      </ComposerEditor>
  
      <a id="mobile-file-upload" class="btn btn-default no-text mobile-file-upload">
        <img class="upImg" src="/images/msgUploadBtn.png" alt="">
        <div style="color: #666666;">Please click to upload photos</div>
      </a>
  
      <div class="main submitBtn">
        <ComposerSaveButton @action={{this.composer.saveAction}} @label="msgboard" @forwardEvent={{true}}
          @disableSubmit={{this.composer.disableSubmit}} />
      </div>
    </div>
  
    --}}
    {{!--
    <PickFilesButton @fileInputId="file-uploader" @allowMultiple={{true}} name="file-uploader" /> --}}
  </div>
  */
  {
    "id": "4ufCI9nl",
    "block": "[[[10,0],[14,0,\"message-board-wrapper\"],[12],[1,\"\\n  \"],[10,\"img\"],[14,0,\"msgb_top\"],[14,\"src\",\"/images/new25bg.png\"],[14,\"alt\",\"\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"header\"],[12],[1,\"\\n    \"],[10,\"h1\"],[14,0,\"title\"],[12],[1,\"Headlines & Skylines\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"greeting\"],[12],[1,\"\\n      \"],[10,0],[12],[1,\"We hope you enjoyed this installment of our 25th Anniversary Exhibition.\"],[13],[1,\"\\n      \"],[10,0],[12],[1,\"Please share your thoughts for Shanghai, or Shanghai Daily.\"],[13],[1,\"\\n      \"],[10,0],[12],[1,\"We’d love to hear from you!\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"msgForm\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,\"placeholder\",\"Your thoughts matter to us! Feel free to leave a comment\"]],[[\"@value\"],[[30,0,[\"formContent\"]]]],null],[1,\"    \"],[13],[1,\"\\n\"],[1,\"\\n\\n\"],[1,\"\\n    \"],[10,0],[14,0,\"uploadImg\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@done\"],[[30,0,[\"uploadSuccess\"]]]],[[\"default\"],[[[],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[1,\"\\n\"],[1,\"      \"],[10,0],[14,0,\"submitBtn\"],[15,\"onclick\",[28,[37,2],[[30,0],\"submitForm\"],null]],[12],[1,\"\\n      \"],[10,0],[14,5,\"display: flex; align-items: center; justify-content: center\"],[12],[1,\"\\n      \"],[10,1],[14,5,\"margin-right: 10px;\"],[12],[1,\"Send\"],[13],[1,\"\\n\"],[41,[30,0,[\"isLoading\"]],[[[1,\"      \"],[1,[28,[35,4],null,[[\"size\"],[\"small\"]]]],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"textarea\",\"uppy-msg-uploader\",\"action\",\"if\",\"loading-spinner\"]]",
    "moduleName": "discourse/plugins/moyun/discourse/templates/sd25th.hbs",
    "isStrictMode": false
  });
});