define("discourse/plugins/moyun/discourse/controllers/reviewtopic", ["exports", "@ember/controller", "@ember/service", "@ember/object", "discourse/lib/ajax", "discourse/models/category", "@glimmer/tracking", "discourse/components/modal/review-modal", "discourse-common/utils/decorators"], function (_exports, _controller, _service, _object, _ajax, _category, _tracking, _reviewModal, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ReviewtopicController = _exports.default = (_class = class ReviewtopicController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "searchTerm", _descriptor, this);
      _initializerDefineProperty(this, "modal", _descriptor2, this);
      _initializerDefineProperty(this, "total", _descriptor3, this);
      _initializerDefineProperty(this, "dialog", _descriptor4, this);
      _initializerDefineProperty(this, "currentPage", _descriptor5, this);
      _initializerDefineProperty(this, "list", _descriptor6, this);
      _initializerDefineProperty(this, "allChecked", _descriptor7, this);
      _initializerDefineProperty(this, "failedloding", _descriptor8, this);
      _initializerDefineProperty(this, "approvedloding", _descriptor9, this);
      _initializerDefineProperty(this, "type", _descriptor10, this);
      _initializerDefineProperty(this, "searchTypes", _descriptor11, this);
      _initializerDefineProperty(this, "listypes", _descriptor12, this);
      _initializerDefineProperty(this, "listypesvla", _descriptor13, this);
      _initializerDefineProperty(this, "Priority_Level", _descriptor14, this);
      _initializerDefineProperty(this, "articles", _descriptor15, this);
      _initializerDefineProperty(this, "chooselist", _descriptor16, this);
    }
    init() {
      this.searchTerm = 'after:2024-06-01';
      super.init(...arguments);
      this._search();
      setTimeout(() => {
        const items = document.querySelectorAll('.top-all');
        items[0].classList.add('active');
      }, 1000);
      this.getAll();
    }
    openreview(item) {
      this.modal.show(_reviewModal.default, {
        model: {
          item,
          reviewthis: this,
          type: this.type
        }
      });
    }
    search() {
      this._search();
    }
    toggleAllCheckboxes(event) {
      this.allChecked = event.target.checked;
      let updatedPosts = this.list.map(post => {
        return {
          ...post,
          isChecked: this.allChecked
        };
      });
      this.list = updatedPosts;
    }
    toggleSingleCheckbox(item) {
      item.isChecked = !item.isChecked;
      this.allChecked = this.list.every(post => post.isChecked);
    }
    getCategory(categoryId) {
      return _category.default.findById(categoryId).name;
    }
    _search() {
      const searchTerm = this.searchTerm;
      const query = encodeURIComponent(searchTerm);
      (0, _ajax.ajax)(`/moyun/review?q=${query}&page=${this.currentPage}&limit=${this.articles}&sortBy=${this.Priority_Level == 1 ? 'status' : 'created_at'}&orderBy=${this.Priority_Level == 1 ? 'Asc' : 'Desc'}&type=${this.type}`, {
        type: "POST",
        headers: {
          Accept: "application/json"
        }
      }).then(async results => {
        for (let i = 0; i < results.list.length; i++) {
          results.list[i].isChecked = false;
          results.list[i].TranslationType = false;
        }
        this.list = results.list;
        this.total = results.pagination.total;
      });
    }
    PriorityLevelchange(val) {
      this.Priority_Level = val;
      this.currentPage = 1;
      this._search();
    }
    dates(val) {
      const date = new Date(val);
      const formattedDate = date.getFullYear() + "-" + String(date.getMonth() + 1).padStart(2, '0') + "-" + String(date.getDate()).padStart(2, '0') + " " + String(date.getHours()).padStart(2, '0') + ":" + String(date.getMinutes()).padStart(2, '0') + ":" + String(date.getSeconds()).padStart(2, '0');
      return formattedDate;
    }
    // 赛选
    getAll() {
      setTimeout(() => {
        const items = document.querySelectorAll('.top-all');
        items.forEach(item => {
          item.addEventListener('click', () => {
            items.forEach(i => i.classList.remove('active'));
            item.classList.add('active');
            if (item.textContent.replace(/\s+/g, '') == "Topic") {
              this.type = "Topic";
            } else {
              this.type = "Post";
            }
            this.currentPage = 1;
            this._search();
          });
        });
      }, 500);
    }

    // 分页
    pagChange(currentPage, articles) {
      this.articles = articles;
      this.currentPage = currentPage;
      this._search();
    }

    // 审核
    failed() {
      let isreview = this.list.some(item => item.isChecked);
      if (!isreview) return this.dialog.alert('Please select the data that needs to be reviewed.');
      this.failedloding = true;
      this.reviewfun('failed');
    }
    approved() {
      let isreview = this.list.some(item => item.isChecked);
      if (!isreview) return this.dialog.alert('Please select the data that needs to be reviewed.');
      this.approvedloding = true;
      this.reviewfun('approved');
    }
    reviewfun(val) {
      const filteredIds = this.list.filter(topic => topic.isChecked).map(topic => topic.id);
      (0, _ajax.ajax)(`moyun/batch_topics`, {
        type: "POST",
        data: {
          operation: val,
          ids: filteredIds,
          type: this.type
        }
      }).then(res => {
        this._search();
        this.dialog.alert(val == 'approved' ? "Approval Successful" : "Cancellation of review successful");
      }).catch(err => {
        this.dialog.alert(err.errorThrown);
      }).finally(() => {
        this.failedloding = false;
        this.approvedloding = false;
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "searchTerm", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "total", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentPage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "list", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "allChecked", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "failedloding", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "approvedloding", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "type", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "Topic";
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "searchTypes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [{
        name: "Priority Level",
        id: 1
      }, {
        name: "Creation Date",
        id: 2
      }];
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "listypes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [{
        name: "post",
        id: 1
      }, {
        name: "topic",
        id: 2
      }];
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "listypesvla", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "Priority_Level", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "articles", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 10;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "chooselist", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openreview", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openreview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "search", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleAllCheckboxes", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAllCheckboxes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleSingleCheckbox", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSingleCheckbox"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "PriorityLevelchange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "PriorityLevelchange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pagChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "pagChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "failed", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "failed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "approved", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "approved"), _class.prototype)), _class);
});