/* import __COLOCATED_TEMPLATE__ from './reply-modal.hbs'; */
import Component from "@ember/component";
import { service } from "@ember/service";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import Composer from "discourse/models/composer";
export default class Replymodal extends Component {
  @service composer
  @service currentUser
  @tracked replythis=this.model.replythis
  init() {
    super.init(...arguments);
  }

  @action
  close() {
    this.composer.cancel()
    this.replyfun()
  }
  @action
  Save(){
    this.composer.saveAction()
    this.replyfun()
  }
  replyfun(){
    let topicinfo=this.get("replythis.model.postStream").posts
    const opts = {
      action: Composer.REPLY,
      draftKey: this.composer.model.draftKey,
      draftSequence: this.composer.model.draftSequence,
      post: topicinfo[0],
      replyflg: true,
      isopenmodal:true
    };
    this.composer.open(opts)
    this.set('composer.replyinputshow',false)
    this.set("composer.model.isreplyshow", false);
    this.closeModal();
  }
  }
