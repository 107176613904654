define("discourse/plugins/moyun/discourse/controllers/notification", ["exports", "@ember/controller", "discourse/lib/ajax", "@glimmer/tracking", "@ember/service", "@ember/object", "discourse/lib/utilities"], function (_exports, _controller, _ajax, _tracking, _service, _object, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let NotificationController = _exports.default = (_class = class NotificationController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "currentUser", _descriptor3, this);
      _initializerDefineProperty(this, "dialog", _descriptor4, this);
      _initializerDefineProperty(this, "site", _descriptor5, this);
      _initializerDefineProperty(this, "activeTab", _descriptor6, this);
      _defineProperty(this, "tabList", [{
        title: "Like"
      }, {
        title: "Comment"
      }, {
        title: "Notice"
      }]);
      _initializerDefineProperty(this, "likeList", _descriptor7, this);
      _initializerDefineProperty(this, "commentList", _descriptor8, this);
      _initializerDefineProperty(this, "noticeList", _descriptor9, this);
      _initializerDefineProperty(this, "pendingList", _descriptor10, this);
      _defineProperty(this, "missType0", ['liked', 'liked_consolidated', 'reaction']);
      _defineProperty(this, "missType1", ['mentioned', 'group_mentioned', 'posted', 'quoted', 'replied']);
      // missType2 ='edited,invited_to_private_message,invitee_accepted,moved_post,linked,granted_badge,invited_to_topic,custom,watching_first_post,topic_reminder,post_approved,code_review_commit_approved,membership_request_accepted,membership_request_consolidated,votes_released,event_reminder,event_invitation,chat_group_mention,assigned,question_answer_user_commented,watching_category_or_tag,new_features,admin_problems,linked_consolidated,following,following_created_topic,following_replied,circles_activity'
      _defineProperty(this, "missType2", []);
    }
    async init() {
      super.init(...arguments);
      this.getLikeList();
    }
    getLikeList() {
      (0, _ajax.ajax)("/notifications", {
        data: {
          limit: 30,
          recent: true,
          bump_last_seen_reviewable: true,
          filter_by_types: "liked,liked_consolidated,reaction",
          silent: true
        }
      }).then(res => {
        this.likeList = res.notifications;
      });
    }
    getCommentList() {
      (0, _ajax.ajax)("/notifications", {
        data: {
          limit: 30,
          recent: true,
          bump_last_seen_reviewable: true,
          filter_by_types: "mentioned,group_mentioned,posted,quoted,replied",
          silent: true
        }
      }).then(res => {
        this.commentList = res.notifications;
      });
    }
    getNoticeList() {
      (0, _ajax.ajax)("/notifications", {
        data: {
          limit: 30,
          recent: true,
          bump_last_seen_reviewable: true
        }
      }).then(res => {
        res.notifications = res.notifications.filter(item => {
          if (!item.data.badge_id && (item.notification_type == 6 || item.notification_type == 20)) {
            return item;
          }
        });
        this.noticeList = res.notifications;
        this.pendingList = res.pending_reviewables;
      });
    }
    switchTab(param) {
      this.activeTab = param;
      switch (param) {
        case 0:
          this.getLikeList();
          break;
        case 1:
          this.getCommentList();
          break;
        case 2:
          this.getNoticeList();
          break;
        default:
          break;
      }
    }
    dismissButtonClick() {
      // by default we display a warning modal when dismissing a notification
      // however we bypass the warning modal for specific notification types when
      // they are a 'low priority' type of notification (eg. likes)
      if (this.renderDismissConfirmation || this.alwaysRenderDismissConfirmation) {
        if (this.currentUser.unread_high_priority_notifications > 0 || this.alwaysRenderDismissConfirmation) {
          this.dismissWarningModal();
        } else {
          this.performDismiss();
        }
      } else {
        this.performDismiss();
      }
    }
    dismissWarningModal() {
      this.modal.show(DismissNotificationConfirmationModal, {
        model: {
          confirmationMessage: this.dismissConfirmationText,
          dismissNotifications: () => this.performDismiss()
        }
      });
    }
    async performDismiss() {
      const opts = {
        type: "PUT"
      };
      let dismissTypes;
      switch (this.activeTab) {
        case 0:
          dismissTypes = this.missType0;
          break;
        case 1:
          dismissTypes = this.missType1;
          break;
        case 2:
          dismissTypes = this.missType2;
          break;
        default:
          break;
      }
      if (dismissTypes?.length > 0) {
        // opts.data = { dismiss_types: dismissTypes };
        opts.data = {
          dismiss_types: dismissTypes.join(",")
        };
      }
      await (0, _ajax.ajax)("/notifications/mark-read", opts);
      if (dismissTypes) {
        const unreadNotificationCountsHash = {
          ...this.currentUser.grouped_unread_notifications
        };
        dismissTypes.forEach(type => {
          const typeId = this.site.notification_types[type];
          if (typeId) {
            delete unreadNotificationCountsHash[typeId];
          }
        });
        this.currentUser.set("grouped_unread_notifications", unreadNotificationCountsHash);
      } else {
        this.currentUser.set("all_unread_notifications_count", 0);
        this.currentUser.set("unread_high_priority_notifications", 0);
        this.currentUser.set("unseen_reviewable_count", 0);
        this.currentUser.set("grouped_unread_notifications", {});
      }
      switch (this.activeTab) {
        case 0:
          this.getLikeList();
          break;
        case 1:
          this.getCommentList();
          break;
        case 2:
          this.getNoticeList();
          break;
        default:
          break;
      }
      (0, _utilities.postRNWebviewMessage)("markRead", "1");
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "activeTab", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "likeList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "commentList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "noticeList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "pendingList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getLikeList", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getLikeList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getCommentList", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getCommentList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getNoticeList", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getNoticeList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchTab", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "switchTab"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dismissButtonClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dismissButtonClick"), _class.prototype)), _class);
});