define("discourse/plugins/moyun/discourse/moyun-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  // export default {
  //   resource: 'admin.adminPlugins',
  //   path: '/plugins',
  //   map() {
  //     this.route('cns-snow');
  //     this.route('usergroups',{path: '/usergroups'});
  //   }
  // };

  function _default() {
    //this.route("ig", { path: "/ig/:name"});
    this.route("ig", {
      path: "/ig/:name",
      resetNamespace: true
    }, function () {
      this.route("topics");
    });

    //this.route("ig", { path: "/ig/:name/topics"});
    this.route('usergroups');
    // this.route("subgroup", { path: "/usergroups/:category_id", resetNamespace: true });
    this.route("subgroup", {
      path: "/usergroups/:id"
    });
    this.route("applystaff");
    this.route("userlogin");
    this.route("register");
    this.route("banner-manager", {
      path: "/banner-manager"
    });
    this.route("sub-banner", {
      path: "/sub-banner"
    });
    this.route("notification", {
      path: "/notification"
    });
    this.route("application-page", {
      path: "/application-page"
    });
    this.route("advertising", {
      path: "/advertising"
    });
    this.route("reviewtopic", {
      path: "/reviewtopic"
    });
    this.route("pointsmanagement", {
      path: "/pointsmanagement"
    });
    this.route("user-terms", {
      path: "/user-terms"
    });
    this.route("privacy-policy", {
      path: "/privacy-policy"
    });
    this.route("sd25th", {
      path: "/sd25th/newtopic",
      resetNamespace: true
    });
  }
});