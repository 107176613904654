define("discourse/plugins/moyun/discourse/routes/pointsmanagement", ["exports", "discourse/routes/discourse", "discourse/lib/ajax"], function (_exports, _discourse, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    renderTemplate() {
      this.render('pointsmanagement');
    },
    async model() {
      let plugins = await (0, _ajax.ajax)(`/admin/plugins/discourse-gamification.json`);
      return {
        gamification_leaderboards: plugins.extras.gamification_leaderboards
      };
    }
  });
});